@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;




/* loader  */
.lds-ring,
.lds-ring div {
  box-sizing: border-box;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #5446d0;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #5446d0 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.showTimeLine {
  animation: showHeightAnimation 0.5s ease-in-out;
}

.hideTimeLine {
  animation: HideHeightAnimation 0.5s ease-in-out;
}

@keyframes showHeightAnimation {
  0% {
    height: 10%;
  }

  100% {
    height: 100%;
  }
}

@keyframes HideHeightAnimation {
  0% {
    height: 100%;
  }

  100% {
    height: 10%;
  }
}

.rdrStaticRangeLabel,
.rdrDateRangeWrapper,
.rdrInputRanges,
.rdrDefinedRangesWrapper {
  background-color: #17171e !important;
}

.rdrDayDisabled,
.rdrInputRangeInput {
  background-color: #1e1e29 !important;
  color: #ffffff !important;
}

.rdrDayNumber span {
  color: #ffffff !important;
}

.rdrStaticRange,
.rdrDateRangePickerWrapper {
  border-bottom: 1px solid #2c2d3c !important;
}

.rdrDefinedRangesWrapper {
  border-right: 1px solid #2c2d3c !important;
}

.rdrDateDisplayWrapper {
  background-color: #1e1e29 !important;
}

.rdrDateDisplayItem {
  background-color: #1e1e29 !important;
  border: 1px solid #ffffff !important;
}

.rdrMonthPicker select,
.rdrYearPicker select {
  background-color: #1e1e29 !important;
  color: #ffffff !important;
}

select {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
}

/* input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;

  background-color: #5446D0;
  border-radius: 0.5rem;
  height: 4px;
  margin-top: 8px;
}

input[type="range"]::-webkit-range-progress {
  background-color: #5446D0;
  border-radius: 0.5rem;
  height: 4px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: 0px;
  background-color: #FFFFFF;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  border: none;
}

input[type="range"]::-moz-range-progress {
  background-color: #5446D0;
  border-radius: 0.5rem;
  height: 4px;
}

input[type="range"]::-moz-range-track {
  background-color: #ABABC0;
  border-radius: 0.5rem;
  height: 4px;
}

input[type="range"]::-moz-range-thumb {
  margin-top: -5px;
  background-color: #FFFFFF;
  border-radius: 100%;
  height: 12px;
  width: 12px;
  border: none;
} */

/* @media screen and (max-width: 1300px) {
  .canvas-size {
    width: 400px !important;
    height: 300px !important;
  }

  .canvas-size .canvas-container {
    width: 400px !important;
    height: 300px !important;
  }

  .canvas-size .canvas-container canvas {
    width: 400px !important;
    height: 300px !important;
  }

} */

.tooltip::after{
  content: "";
  position: absolute;
  bottom: 100%;
  left: 15px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

input:-webkit-autofill {
  background-color: #17171e !important; /* Custom background color */
  -webkit-box-shadow: 0 0 0 30px #17171e inset !important; /* Extend the background color */
  -webkit-text-fill-color: #ababc0 !important; /* Text color */
  outline: none !important;
  outline-offset: 0px !important;
}

input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: #17171e !important;
  -webkit-box-shadow: 0 0 0 30px #17171e inset !important;
  -webkit-text-fill-color: #ababc0 !important;
  outline: none !important;
  outline-offset: 0px !important;
}

.rdrMonthPicker select, .rdrYearPicker select {
  background-color: #fff !important;
  color: #17171e !important;
  text-align: left !important;
}

/* CustomDropdown.css */

.dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdown-toggle {
  width: 100%;
  padding: 10px;
  background-color: #3b3b4f;
  color: white;
  cursor: pointer;
  text-align: left;
  margin-top: 16px;
  max-height: 41px;
  font-size: 14px;
}

.dropdown-menu {
  position: absolute;
  width: 100%;
  color: white;
  background-color: #1C1C26;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  z-index: 1000;
  border-radius: 5px;
  overflow: hidden;
}

.dropdown-item {
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-item:hover {
  background-color: #1967d2;
}



/* Hide native checkbox */
.custom-checkbox input[type="checkbox"] {
  display: none;
}

/* Style the label as the checkbox */
.custom-checkbox label {
  display: inline-block;
  width: 18px;
  height: 18px;
  border: 1.5px solid #ababc0;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  transition: border-color 0.3s ease-in-out;
  align-self: center;
  display: flex;
  margin: auto;
}

/* Add a checkmark for the checked state */
.custom-checkbox input[type="checkbox"]:checked + label:before {
  content: '✓'; /* Checkmark */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff; /* Checkmark color */
  font-size: 12px; /* Adjust size */
  font-weight: bold;
  background-color: #5446d0;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

/* Optional: Hover effect */
.custom-checkbox label:hover {
  border-color: #fff;
}

.border-shape
{
  content: '';
  position: absolute;
  top: -8px;
  right: -20px;
  width: 0px;
  height: 0px;
  rotate: 180deg;
  border-style: solid;
  border-width : 30px 29px 28px 0; 
  border-color: transparent #3B3B4F transparent transparent
}

.pagination {
  display: flex;
  list-style: none;
  cursor: pointer;
  border-collapse: collapse;
}

.pagination a {
  padding: 7px 15px;
  border: 1px solid #3b3b4f;
  color: #fff;
}

.pagination__link--active a {
  color: #fff;
  background: #5446d0;
}

.pagination__link--disabled a {
  color: #c6c5ca;
  border: 1px solid #3b3b4f;
}


.skeleton {
  background: linear-gradient(
    90deg,
    #ffffff1a 25%,
    #ffffff33 50%,
    #ffffff1a 75%
  );
  background-size: 400px 100%;
  animation: shimmer 1.5s infinite linear;
}
@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}